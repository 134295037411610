import React from "react"

import { Box, Typography, Button } from "@mui/material"

import { Link as GatsbyLink, useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useQueryParam } from "use-query-params"

import "swiper/css"
import "swiper/css/pagination"
import "aos/dist/aos.css"

import Layout from "../components/layout"
import IndustryCard from "../components/IndustryCard"
import Link from "../components/Link"

import FeaturedResources from "../components/featured-resources"
import FullWidthStripe from "../components/FullWidthStripe"
import ProductGroup from "../components/ProductGroup"
import EnlargePictureCard from "../components/EnlargePictureCard"
import GradientImageCard from "../components/GradientImageCard"
import SectionHeaer from "../components/SectionHeader"

const Globe = ({ image, video }) => {
  return (
    <Box
      sx={{
        video: {
          maxWidth: 1,
        },
      }}
    >
      <video
        playsInline
        autoPlay
        muted
        preload
        controls={false}
        loop
        poster={image.localFile.url}
      >
        <source src={video.localFile.url} type="video/mp4" />
      </video>
    </Box>
  )
}
const Partners = ({ header, cards }) => {
  return (
    <>
      <SectionHeaer
        overline={header.overline}
        header={header.title}
        description={header.description}
      />
      <Box display="flex" flexWrap="wrap" gap={6} width={1}>
        {cards.map(({ defaultImage, highlightedImage }, index) => (
          <Box
            key={index}
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={132}
            flex={{ xs: "1 100%", md: "1 45%", lg: "1 20%" }}
            borderRadius={1}
            position="relative"
            boxShadow={26}
            sx={{
              transition: "box-shadow 0.5s",
              "&:hover": {
                boxShadow: 27,
              },
            }}
          >
            <GatsbyImage
              loading="eager"
              image={getImage(defaultImage.localFile)}
              alt="gray client logo"
            />
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              position="absolute"
              width={1}
              height={1}
              sx={{
                opacity: 0,
                "&:hover": { opacity: 1 },
                transition: "opacity 0.5s",
              }}
            >
              <GatsbyImage
                loading="eager"
                image={getImage(highlightedImage.localFile)}
                alt="colorful client logo"
              />
            </Box>
          </Box>
        ))}
      </Box>
    </>
  )
}

const IndexPage = () => {
  const {
    strapiPocetnaStranica: {
      spinningGlobe,
      glavniBanner,
      industries,
      partners,
      industryGroups,
    },
    strapiProductsPage: productsSection,
    allStrapiArticle: { nodes: featuredArticles },
  } = useStaticQuery(articlesQuery)

  const clientsRef = React.useRef(null)
  const productsRef = React.useRef(null)
  const industriesRef = React.useRef(null)
  const [sectionParam] = useQueryParam("section")

  React.useEffect(() => {
    if (sectionParam === "clients")
      clientsRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
    if (sectionParam === "products")
      productsRef.current.scrollIntoView({ behavior: "smooth" })
    if (sectionParam === "industries")
      industriesRef.current.scrollIntoView({
        behavior: "smooth",
      })
  }, [clientsRef, productsRef, sectionParam])

  return (
    <Layout darkNavbar>
      <FullWidthStripe
        mt="-1px"
        bgcolor="#000"
        minHeight="100vh"
        display="flex"
        pb={{ xs: 6, lg: 0 }}
      >
        <Box
          flex={1}
          maxWidth={{ xs: 1350, xl: "80vw" }}
          position="relative"
          display="flex"
          flexDirection={{ xs: "column", lg: "row" }}
          justifyContent={{ xs: "center", lg: "space-between" }}
          gap={{ xs: 5, lg: 0 }}
          alignItems={{ lg: "center" }}
          bgcolor="black"
          px={{ xs: 2, sm: 5, lg: 10, xl: 0 }}
        >
          <Box
            mx="auto"
            zIndex={1}
            position={{ xs: "unset", lg: "absolute" }}
            top={{ lg: "50%" }}
            sx={{ transform: { lg: "translateY(-50%)" } }}
            display="flex"
            flexDirection="column"
            gap={1}
            order={{ xs: 2, lg: 1 }}
          >
            <Typography
              variant="h1"
              color="primary.contrastText"
              fontSize={{ xs: "1.75rem !important", md: "2.75rem !important" }}
              maxWidth={545}
            >
              {glavniBanner.title}
              <Box component="span" color="primary.main">
                .
              </Box>
            </Typography>
            <Typography variant="body1" color="primary.contrastText">
              {glavniBanner.subtitle.data.subtitle}
            </Typography>
            <Box mt={2} sx={{ a: { textDecoration: "none !important" } }}>
              <GatsbyLink to={glavniBanner.leftButton.uri}>
                <Button
                  aria-label={glavniBanner.leftButton.text}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  {glavniBanner.leftButton.text}
                </Button>
              </GatsbyLink>
            </Box>
          </Box>
          <Box
            display={{ xs: "none", lg: "block" }}
            flex={1}
            minWidth={350}
            order={{ xs: 1, lg: 2 }}
          />
          <Box order={{ xs: 1, lg: 2 }} width={{ xs: 1, lg: "auto" }}>
            <Box mx="auto" width={{ xs: 1, md: 550 }} height={1}>
              <Globe image={glavniBanner.image} video={spinningGlobe} />
            </Box>
          </Box>
        </Box>
      </FullWidthStripe>
      <Box display="flex" flexDirection="column" gap={7.5} maxWidth={1}>
        <SectionHeaer
          overline={industries.header.overline}
          header={industries.header.title}
          description={industries.header.description}
        />
        <Box display="flex" flexDirection="row" flexWrap="wrap" gap={4}>
          {industries.cards.map(industry => (
            <IndustryCard key={industry.title} {...industry} />
          ))}
        </Box>
      </Box>
      <Box
        ref={productsRef}
        display="flex"
        flexDirection="column"
        gap={{ xs: 3, md: 10 }}
      >
        <SectionHeaer
          overline={productsSection.overline}
          header={productsSection.header}
          description={productsSection.description}
        />

        <Box display="flex" flexWrap="wrap" gap={4}>
          <Link
            href={productsSection.leftCard.uri}
            sx={{
              flex: { xs: "1 100%", md: "1 40%", lg: 1 },
              order: 1,
            }}
          >
            <EnlargePictureCard {...productsSection.leftCard}>
              <Box
                mt={6.5}
                display="flex"
                flexDirection="row-reverse"
                width={1}
                sx={{ img: { borderRadius: 3 } }}
              >
                <GatsbyImage
                  loading="eager"
                  image={getImage(
                    productsSection.leftCard.backgroundImage.localFile
                  )}
                />
              </Box>
              <Box
                className="enlarge-photo"
                position="absolute"
                left={0}
                bottom={0}
                width={0.875}
              >
                <GatsbyImage
                  loading="eager"
                  image={getImage(
                    productsSection.leftCard.enlargingImage.localFile
                  )}
                />
              </Box>
            </EnlargePictureCard>
          </Link>
          <Box
            display="flex"
            flexDirection={{ xs: "column", md: "row", lg: "column" }}
            order={{ xs: 3, lg: 2 }}
            gap={4}
            sx={{ flex: { xs: "1 100%", md: "1 40%", lg: 1 } }}
          >
            <Link href={productsSection.middleTopCard.link} sx={{ flex: 1 }}>
              <GradientImageCard {...productsSection.middleTopCard}>
                <GatsbyImage
                  loading="eager"
                  image={getImage(
                    productsSection.middleTopCard.image.localFile
                  )}
                />
              </GradientImageCard>
            </Link>

            <Link href={productsSection.lowerMiddleCard.link} sx={{ flex: 1 }}>
              <GradientImageCard {...productsSection.lowerMiddleCard}>
                <GatsbyImage
                  loading="eager"
                  image={getImage(
                    productsSection.lowerMiddleCard.image.localFile
                  )}
                />
              </GradientImageCard>
            </Link>
          </Box>

          <Link
            href={productsSection.rightCard.uri}
            sx={{
              display: { xs: "flex", lg: "block" },
              flex: { xs: "1 100%", md: "1 40%", lg: 1 },
              order: { xs: 2, lg: 3 },
            }}
          >
            <EnlargePictureCard {...productsSection.rightCard}>
              <Box visibility="hidden" width={0.5}>
                <GatsbyImage
                  loading="eager"
                  image={getImage(
                    productsSection.rightCard.enlargingImage.localFile
                  )}
                />
              </Box>
              <Box
                className="enlarge-photo"
                position="absolute"
                display="flex"
                flexDirection="row-reverse"
                right={0}
                bottom={0}
                width={{ xs: 1, xl: 0.925 }}
                sx={{ img: { borderRadius: 3 } }}
              >
                <GatsbyImage
                  loading="eager"
                  image={getImage(
                    productsSection.rightCard.enlargingImage.localFile
                  )}
                />
              </Box>
            </EnlargePictureCard>
          </Link>
        </Box>
      </Box>
      <Box
        ref={industriesRef}
        display="flex"
        flexDirection="column"
        gap={{ xs: 6, md: 8, lg: 15 }}
      >
        {industryGroups.map((group, index) => (
          <ProductGroup
            key={index}
            {...group}
            textOrder={index % 2 === 0 ? 1 : 2}
          />
        ))}
      </Box>
      <Box
        ref={clientsRef}
        display="flex"
        flexDirection="column"
        gap={7.5}
        mt={[-4, 0]}
      >
        <Partners {...partners} />
      </Box>
      <Box mt={{ lg: -8 }}>
        <FeaturedResources
          resourceURI="/articles"
          overline="Articles"
          title="Featured News Articles"
          resources={featuredArticles}
          bgcolor="white"
        />
      </Box>
    </Layout>
  )
}

const articlesQuery = graphql`
  query ArticlesQuery {
    strapiPocetnaStranica {
      spinningGlobe {
        localFile {
          url
        }
      }
      glavniBanner {
        title
        subtitle {
          data {
            subtitle
          }
        }
        image {
          localFile {
            url
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        leftButton {
          uri
          text
        }
        rightButton {
          uri
          text
        }
      }

      industries {
        header {
          overline
          title
          description
        }
        cards {
          title
          description
          icon {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
              ext
              publicURL
            }
          }
        }
      }
      featuredProducts {
        header {
          overline
          title
          description
        }
        cards {
          description
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          readMoreText
          readMoreLink
          colorTag
        }
      }
      otherProducts {
        header {
          overline
          title
          description
        }
        cards {
          title
          description
          colorTag
        }
      }
      partners {
        header {
          overline
          title
          description
        }
        cards {
          uri
          defaultImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          highlightedImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
      industryGroups {
        header
        description
        items {
          content
        }
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }

    strapiProductsPage {
      overline
      header
      description

      leftCard {
        overline
        header
        backgroundColor
        primaryTextColor
        secondaryTextColor
        uri
        backgroundImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        enlargingImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }

      middleTopCard {
        overline
        header
        gradientVariant
        primaryTextColor
        secondaryTextColor
        link
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }

      lowerMiddleCard {
        overline
        header
        gradientVariant
        primaryTextColor
        secondaryTextColor
        link
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }

      rightCard {
        overline
        header
        backgroundColor
        primaryTextColor
        secondaryTextColor
        uri
        backgroundImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        enlargingImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }

    allStrapiArticle(sort: { fields: publishedAt, order: DESC }, limit: 3) {
      nodes {
        strapi_id
        readTime
        publishedAt
        header {
          title
          subtitle
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            url
          }
        }
      }
    }
  }
`

export default IndexPage
