import React from "react"
import { Box, Typography } from "@mui/material"
import { BsArrowRight } from "react-icons/bs"

const gradient = {
  light: {
    subtle:
      "linear-gradient(-270.61deg, rgba(255, 255, 255, 0.3) 0.41%, rgba(255, 255, 255, 0.139063) 26.66%, rgba(255, 254, 254, 0) 54.81%)",
    intense:
      "linear-gradient(-270.61deg, #FFFFFF 0.41%, rgba(255, 255, 255, 0.463542) 26.66%, rgba(255, 254, 254, 0) 54.81%)",
  },
  dark: {
    subtle:
      "linear-gradient(-269.71deg, rgba(0, 0, 0, 0.5) 2.83%, rgba(0, 0, 0, 0) 79.81%)",
    intense:
      "linear-gradient(-269.71deg, #000000 2.83%, rgba(0, 0, 0, 0) 79.81%)",
  },
}

const GradientImageCard = ({
  overline,
  header,
  primaryTextColor,
  secondaryTextColor,
  gradientVariant,
  children,
}) => {
  return (
    <Box
      borderRadius={3}
      sx={{
        boxShadow: "5px 5px 30px 10px rgba(35, 66, 101, 0.1)",
        ":hover": {
          boxShadow: "5px 5px 20px 10px rgba(110, 130, 154, 0.2)",
          ".intense-gradient": {
            opacity: 0,
          },
        },
        transition: "box-shadow 0.5s",
      }}
    >
      <Box position="relative">
        <Box
          position="absolute"
          zIndex={100}
          pl={4}
          pt={5.5}
          width={0.6}
          display="flex"
          flexDirection="column"
          gap={2.5}
        >
          <Typography
            variant="overline"
            fontSize="0.75rem !important"
            color={secondaryTextColor}
          >
            {overline}
          </Typography>
          <Typography variant="h5" fontWeight={400} color={primaryTextColor}>
            {header}
          </Typography>
          <BsArrowRight size={40} color={secondaryTextColor} />
        </Box>

        <Box
          className="intense-gradient"
          zIndex={10}
          width={1}
          height={1}
          position="absolute"
          sx={{
            borderRadius: 3,
            background: gradient[gradientVariant].intense,
            opacity: 1,
            transition: "opacity 0.5s",
          }}
        />
        <Box
          zIndex={10}
          width={1}
          height={1}
          position="absolute"
          sx={{
            borderRadius: 3,
            background: gradient[gradientVariant].subtle,
          }}
        />
        <Box
          height={{ xs: 250, sm: 300, lg: 250, xl: 300 }}
          sx={{
            "& *": { height: 1, borderRadius: 3 },
            img: {
              objectFit: "cover",
              width: "100%",
              height: "auto",
            },
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  )
}

export default GradientImageCard
