import React from "react"
import { Box, Typography } from "@mui/material"

// import { GoPrimitiveDot } from "react-icons/go"
import { IoCaretForward } from "react-icons/io5"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const ProductGroup = ({ textOrder = 1, header, description, items, image }) => {
  return (
    <Box
      display="flex"
      flexWrap="wrap"
      flex={1}
      gap={{ xs: 2, md: 0, lg: 7.5 }}
    >
      <Box
        display="flex"
        flexDirection="column"
        gap={1.5}
        flex={1}
        order={textOrder}
      >
        <Typography variant="h3">{header}</Typography>
        <Typography variant="body1" color="text.secondary" sx={{ mb: 0.5 }}>
          {description}
        </Typography>

        <Box
          display="flex"
          flexDirection="column"
          // gap={{ xs: 2, md: 3 }}
          // mt={1.5}
        >
          {items.map(({ content }) => (
            <Box
              key={content}
              display="flex"
              gap={1}
              py={1.5}
              sx={({ palette }) => ({
                svg: { color: palette.primary.main },
              })}
            >
              <IoCaretForward size={20} />
              <Typography
                variant="body2"
                fontWeight={600}
                sx={{
                  fontSize: { xs: "0.9rem", sm: "1rem", lg: "1.125rem" },
                }}
              >
                {content}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        flex={{ xs: "1 100%", lg: 1 }}
        order={{ xs: 0, lg: 1 }}
      >
        <GatsbyImage loading="eager" image={getImage(image.localFile)} />
      </Box>
    </Box>
  )
}

export default ProductGroup
