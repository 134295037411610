import React from "react"

import { Box, Typography, useMediaQuery } from "@mui/material"

import { Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import FeaturedResource from "./FeaturedResource"

import "swiper/css"
import "swiper/css/pagination"

const getSwiperStyles = ({ palette }) => ({
  ".swiper": {
    marginLeft: -4,
  },
  ".swiper-pagination": {
    bottom: "0px !important",
  },
  ".swiper-pagination-bullet-active": {
    bgcolor: palette.primary.main,
  },
})

const FeaturedResources = ({
  resourceURI,
  overline,
  title,
  resources,
  bgcolor = "#f8f8f8",
}) => {
  const isLgUp = useMediaQuery(theme => theme.breakpoints.up("xl"))
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up("md"))

  return (
    <Box
      width="100vw"
      ml={({}) => ({
        xs: `calc((-100vw + 100%) / 2)`,
      })}
      sx={({}) => ({
        py: 12,
        pl: { xs: 2, sm: 4, md: 5, lg: 10, xl: 0 },
        pr: { xs: 0, sm: 3, md: 4, lg: 8, xl: 0 },
        bgcolor,
      })}
    >
      <Box
        display="flex"
        flexDirection="column"
        mx="auto"
        maxWidth={{ xs: 1365, lg: "80vw" }}
      >
        <Typography
          fontWeight={500}
          variant="overline"
          color="primary"
          sx={{ textTransform: "uppercase" }}
        >
          {overline}
        </Typography>
        <Typography variant="h2" sx={{ mb: { xs: 4, md: 8 }, maxWidth: 500 }}>
          {title}
        </Typography>

        <Box maxWidth={1} sx={theme => ({ ...getSwiperStyles(theme) })}>
          <Swiper
            modules={[Pagination]}
            slidesPerView={isLgUp ? 3 : isMdUp ? 2 : 1}
            pagination
          >
            {resources.map((resource, index) => (
              <SwiperSlide key={resource.strapi_id || index}>
                <FeaturedResource resourceURI={resourceURI} {...resource} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Box>
    </Box>
  )
}

export default FeaturedResources
