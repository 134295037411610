import React from "react"

import { Box, Typography, Card } from "@mui/material"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const IndustryCard = ({ title, description, icon }) => {
  return (
    <Card
      sx={theme => ({
        border: "1px solid #E5E5E5",
        boxShadow: "none",

        maxWidth: {
          xs: "100%",
          md: `calc(50% - (${theme.spacing(4)}/2))`,
          xl: `calc(33.33% - 2*(${theme.spacing(4)}/3))`,
        },
        flex: {
          xs: "1 100%",
          md: "1 50%",
          xl: "33.33%",
        },
      })}
    >
      <Box display="flex" px={3.5} pt={3.5} height={172} gap={2}>
        <Box
          mt="-3px"
          height="36px"
          width="36px !important"
          sx={{
            ".gatsby-image-wrapper": { width: "36px" },
            img: { width: "36px", height: "36px" },
          }}
        >
          {icon.localFile.ext !== ".svg" && (
            <GatsbyImage image={getImage(icon.localFile)} alt={title} />
          )}
          {icon.localFile.ext === ".svg" && (
            <img src={icon.localFile.publicURL} alt="Industry image" />
          )}
        </Box>

        <Box display="flex" flexDirection="column">
          <Typography
            variant="body1"
            fontWeight={900}
            gutterBottom
            lineHeight={1}
          >
            {title}
          </Typography>
          <Typography variant="body2" fontWeight={300}>
            {description}
          </Typography>
        </Box>
      </Box>
    </Card>
  )
}

export default IndustryCard
