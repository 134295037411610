import React from "react"
import { Box, Typography } from "@mui/material"
import { BsArrowRight } from "react-icons/bs"

const EnlargePictureCard = ({
  overline,
  header,
  backgroundColor,
  primaryTextColor,
  secondaryTextColor,
  children,
}) => {
  return (
    <Box sx={{ display: { xs: "flex", lg: "block" }, height: 1 }}>
      <Box
        borderRadius={3}
        bgcolor={backgroundColor}
        sx={{
          boxShadow: "5px 5px 30px 10px rgba(35, 66, 101, 0.1)",
          display: { xs: "flex", lg: "block" },
          justifyContent: "space-between",
          flexDirection: "column",
          ".enlarge-photo": {
            transition: "width 0.5s",
          },
          ":hover": {
            boxShadow: "5px 5px 20px 10px rgba(110, 130, 154, 0.2)",
            ".enlarge-photo": {
              width: 1,
            },
          },
          transition: "box-shadow 0.5s",
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          pl={4}
          pt={5.5}
          gap={2.5}
          width={0.6}
        >
          <Typography
            variant="overline"
            fontSize="0.75rem !important"
            color={secondaryTextColor}
          >
            {overline}
          </Typography>
          <Typography variant="h5" fontWeight={400} color={primaryTextColor}>
            {header}
          </Typography>
          <BsArrowRight size={40} color={secondaryTextColor} />
        </Box>
        <Box
          position="relative"
          display="flex"
          flexDirection="row-reverse"
          width={1}
        >
          {children}
        </Box>
      </Box>
    </Box>
  )
}

export default EnlargePictureCard
