import React from "react"
import { Box, Typography } from "@mui/material"

const SectionHeaer = ({ overline, header, description }) => {
  return (
    <Box display="flex" flexDirection="column" width={1}>
      <Typography
        variant="overline"
        color="primary"
        fontWeight={500}
        sx={{ mb: 0.5 }}
      >
        {overline}
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        gap={{ xs: 2, lg: 6 }}
        justifyContent="space-between"
      >
        <Typography variant="h1" sx={{ flex: 1, maxWidth: { xs: 1, lg: 545 } }}>
          {header}
          <Typography
            variant="inherit"
            color="primary"
            sx={{ display: "inline-block" }}
          >
            .
          </Typography>
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ flex: { xs: "1 100%", lg: 1 }, maxWidth: { xs: 1, lg: 545 } }}
        >
          {description}
        </Typography>
      </Box>
    </Box>
  )
}

export default SectionHeaer
